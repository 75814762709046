import React from "react";
import "./App.css";
import LabelsForm from "./components/labels-form/LabelsForm";

function App() {
	return (
		<div className="App">
			<LabelsForm initialQuantity={50}/>
		</div>
	);
}

export default App;
