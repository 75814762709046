import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {ReactNode, useEffect, useState} from "react";

export interface ISelectorDataObj {
    name: string;
    value: any
}

interface ISelectorProps {
    label: string;
    value: any;
    onChange: (event: SelectChangeEvent) => void
    data: ISelectorDataObj[]
}

export const Selector = ({label, value, onChange, data}: ISelectorProps) => {
	const [items, setItems] = useState<ReactNode[]>([]);

	useEffect(() => {
		const tp = [];
		for (const item of data) {
			tp.push(<MenuItem value={item["value"]}>{item.name}</MenuItem>);
		}

		setItems(tp);
	}, [data]);

	return (
		<FormControl sx={{minWidth: 120}}>
			<InputLabel>{label}</InputLabel>
			<Select
				value={value}
				label={label}
				onChange={onChange}
			>
				{items};
			</Select>
		</FormControl>
	);
};
