import {getFactories} from "./factories.service";
import tagsData from "./tags-data.json";

export interface ITags {
    value: string;
    fullDisplayName: string;
}

const fetchSupplementaryTags = async () => {
	const tags: ITags[] = tagsData;


	return tags;
};

// getTags will get factories info and concatenate supplementary tags from another database.
export const getTags = async () => {
	let tags: ITags[];

	const factories = await getFactories();

	tags = factories.map((factory) => {
		return {value: factory.factory_code, fullDisplayName: factory.factory_code + " - " + factory.factory_name};
	});

	tags = tags.concat(await fetchSupplementaryTags());

	return tags;
};
