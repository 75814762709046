import React from "react";

/* Components */
import {Button} from "@mui/material";

/* Styles */
import "./QuantitySelector.css";

interface QuantitySelectorProps {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>
    min?: number;
    max?: number;
    step?: number;
}

export const QuantitySelector = ({value, setValue, min = 0, max = Number.MAX_VALUE, step = 1}: QuantitySelectorProps) => {

	const decreaseQty = () => {
		const newQty = value - step <= min ? min : value - step;

		setValue(newQty);
	};

	const increaseQty = () => {
		const newQty = value + step >= max ? max : value + step;

		setValue(newQty);
	};

	return (
		<div className={"LabelsForm"}>
			<Button variant={"outlined"} onClick={decreaseQty}>-</Button>
			<input value={value}/>
			<Button variant={"contained"} onClick={increaseQty}>+</Button>
		</div>
	);
};

export default QuantitySelector;
