import axios, {AxiosError} from "axios";
import Joi from "joi";

const labelsApiUrl = process.env.REACT_APP_LABELS_API;
console.log("LABELS API PROVIDER: " + labelsApiUrl);

export const createLabels = async (amount: number, tag: string) => {
	const data = {amount, tag};

	const schema = Joi.object().keys({
		amount: Joi.number().integer().positive().required(),
		tag: Joi.string().case("upper").required(),
	});

	const {value, error} = schema.validate(data);

	if (typeof error !== "undefined") {
		throw error;
	}

	let result;
	try {
		result = await axios.post(labelsApiUrl + "/labels", value);
	} catch (e) {
		if (e instanceof AxiosError) {
			let errorMessage = e.message;
			if (typeof e.response !== "undefined" && e.response.status >= 400) {
				errorMessage += ": " + e.response.data.message;
			}
			throw new Error(errorMessage);
		}
		throw e;
	}

	return result.data;
};
