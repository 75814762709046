import QuantitySelector from "../quantity-selector/QuantitySelector";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Alert, Button, SelectChangeEvent, Stack} from "@mui/material";
import {Download, Save, Send} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {createLabels} from "../../../services/labels.service";
import {getFactories, IFormatedFactory} from "../../../services/factories.service";
import CsvDownload from "react-json-to-csv";
import {ISelectorDataObj, Selector} from "../select/Selector";
import "./LabelsForm.css";
import {getTags} from "../../../services/tags.service";

interface ILabelsFormProps {
    initialTag?: string
    initialQuantity?: number;
}

interface ILabelData {
    number: number;
    tag: string;
    code: string;
}

export const LabelsForm = ({initialTag = "", initialQuantity = 0}: ILabelsFormProps) => {
	const [labelsTag, setLabelsTag] = useState(initialTag);
	const [labelsQty, setLabelsQty] = useState(initialQuantity);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string|null>(null);
	const [selectData, setSelectData] = useState<ISelectorDataObj[]>([]);
	const [labelsData, setLabelsData] = useState<ILabelData[]|null>(null);
	const [csvFileName, setCsvFileName] = useState("labels.csv");


	// Update tags
	useEffect(() => {
		getTags()
			.then(tags => {
				const selectData: ISelectorDataObj[] = tags.map(tag => {
					return {name: tag.fullDisplayName, value: tag.value};
				});

				selectData.sort((a, b) => {
					if (a.name.toUpperCase() < b.name.toUpperCase()) {
						return -1;
					}

					return 0;
				});

				setSelectData(selectData);
			});
	}, []);


	const getFileName = (data: ILabelData[]) => {
		const tag = data[0]["tag"];
		const first = data[0]["number"];
		const last = data[data.length - 1]["number"];


		return tag + "_" + first + "-" + last + ".csv";
	};

	const getLabels = async () => {
		setError(null);
		setIsLoading(true);
		let res;
		try {
			res = await createLabels(labelsQty, labelsTag);
		} catch (e) {
			if (e instanceof Error) {
				console.log("E.MESSAGE: " + e.message);
				setError(e.message);
			} else {
				console.log("OULAH");
				setError("Something went wrong.");
			}
			return;
		} finally {
			setIsLoading(false);
		}


		setCsvFileName(getFileName(res));
		setLabelsData(res);
		setLabelsTag("");
		console.log("RES: ", res);
	};

	const setTag = (event: SelectChangeEvent<string>) => {
		setLabelsTag(event.target.value);
	};

	return (
		<Stack alignItems={"center"} spacing={3}>
			<h3>Box labels</h3>
			{error !== null
				? <Alert severity={"error"}>{error}</Alert>
				: null
			}
			<Selector label={"Tag"} value={labelsTag} onChange={setTag} data={selectData}/>
			<QuantitySelector value={labelsQty} setValue={setLabelsQty} min={50} max={500} step={50}/>
			{isLoading
				? <LoadingButton
					loading
					loadingPosition="start"
					startIcon={<Send />}
					variant="contained">Creating tags... </LoadingButton>
				: <Button variant={"contained"} endIcon={<Send/>} onClick={getLabels}>Create</Button>
			}
			{labelsData === null
				? null
				: <CsvDownload className={"labelsform-download-button"} filename={csvFileName} data={labelsData}><Button variant={"contained"} color={"success"} startIcon={<Download/>}>{csvFileName}</Button></CsvDownload>
			}
		</Stack>
	);
};

export default LabelsForm;
