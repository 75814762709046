import factoriesData from "./factories-data.json";

interface IFactory {
    "factory_name": string;
    "factory_name_CN": string;
    "wechat_name": string|number;
    "phone": string|number;
    "image": string;
    "cn_newyear_closing": string;
    "cn_newyear_reopening": string;
    "as_id_qc": string;
    "contact_name": string;
    "Street": string;
    "City": string;
    "State": string;
    "Country": string;
    "Zip": string;
    "speciality": string;
    "factory_code": string;
    "_ComputedAddress": string;
    "Related app_packing_cartons_generates": string;
    "Related app_packing_carton_barcodes": number | string;
    "": string;
}

export interface IFormatedFactory {
    "factory_name": string;
    "factory_code": string;
}

export const getFactories = async () => {
	const factoryData: IFactory[] = factoriesData;

	return formatFactoryData(factoryData);
};

const formatFactoryData = (factoryData: IFactory[]): IFormatedFactory[] => {
	return  factoryData.map((factory) => {
		return {factory_name: factory.factory_name, factory_code: factory.factory_code};
	});
};
